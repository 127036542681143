* {
  color: rgb(218, 25, 25);
}

#scroll-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 0.75rem;
  background-color: black;
  border-color: rgba(0, 0, 0, 0);
  align-items: center; 
  border-radius: 9999px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#scroll-to-top-btn:hover {
  background-color: rgb(35,35,35);
}

.scroll-to-top-icon {
  height: 2rem;
  width: 2rem;
}

/*The entire site, found it on the "inspect element page"*/
.site {
  background-color: rgb(45,48,48);
}

.transform-content {
  animation: fadeInContent 1.5s;
}

@keyframes fadeInContent {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.audio-player {
  width: 80%;
}

.contact-form .modal-content {
  background-color: black;
}

option {
  color: black;
}

#contact-button {
  background-color: #530000;
  color: white;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
}

#schedule-button {
  background-color: #530000;
  color: white;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
}

.nav-logo {
  width: 14vw;
  margin-top: -13px;
  margin-bottom: -13px;
}

#responsive-navbar-nav {
  text-align: right;
}

/* Used for the navbar */
.flex-grow-1 {
  font-size: 3vw;
}

.portfolio-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-style: solid;
  padding: 1rem;
  min-width: 10rem;
  animation: fadeInPortfolio 2s;
}

.portfolio-row {
  padding-bottom: 1rem;
  animation: fadeInPortfolio 2s;
}

@keyframes fadeInPortfolio {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.form-label {
  font-size: 3vh;
}

strong {
  color: rgb(255,0,0);
  font-size: 6vh;
  padding-bottom: 4vh;;
}

p {
  font-size: 4vh;
}

.home-container {
  background-color: rgb(45,48,48);
  /*border-style: solid;*/
  padding-top: 6vh;
}

#home {
  text-align: center;
  font-size: 3vh;
}

.contact-container {
  margin-bottom: 6vh;
}

.service-rates {
  font-size: 3.5vh;
  padding-bottom: 1.5vh;
}